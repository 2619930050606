// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require('@rails/ujs').start()
require('turbolinks').start()
require('@rails/activestorage').start()
import 'channels'

import 'bootstrap'
import 'moment/locale/de'
import 'daterangepicker/daterangepicker'
import 'select2'
import 'stylesheets/application'
import 'cocoon-js'
import 'jquery-contextmenu'
import 'jquery-ui/ui/widget'
import 'jquery-ui/ui/widgets/sortable'

require('data-confirm-modal')

import tinymce from 'tinymce/tinymce'
import 'tinymce/icons/default'
import 'tinymce/plugins/link'
import 'tinymce/plugins/autoresize'
import 'tinymce/plugins/image'
import 'tinymce/plugins/pagebreak'
import 'tinymce/themes/silver'
import 'tinymce-i18n/langs5/de'
global.tinymce = tinymce

import $ from 'jquery'
global.$ = jQuery

function requireAll(context) {
  context.keys().forEach(context)
}
requireAll(require.context('custom/', true, /\.js$/))
