document.addEventListener("turbolinks:load", function() {
  initialize()

  $('select.webinar_participant').select2({
    width: '100%',
    theme: "bootstrap4",
    minimumInputLength: 3,
    ajax: {
      url: $('.selectpicker.webinar_participant').data("url"),
      contentType: "application/json",
      dataType: 'json',
      data: function (params) {
        return { search: params.term }
      },
      processResults: function (data) {
        return { results: data.results }
      }
    }
  })
})

$(document).ajaxComplete(function() {
  initialize()
});

$(document).on("click", ".add_fields", function() {
  initialize()
})

$(document).on('shown.bs.modal', function (event) {
  initialize()
})

function initialize() {
  dataConfirmModal.setDefaults({
    title: "Anfrage senden",
    backdrop: 'static',
    keyboard: false,
    commit: $(".confirm-modal").data("commit"),
    cancel: $(".confirm-modal").data("cancel")
  });

  $('select:not(#selected_id):not(.webinar_participant)').select2(selectOptions)

  function fixWidthHelper(e, ui) {
    ui.children().each(function () {
      $(this).width($(this).width());
    });
    return ui;
  }

  $('.sortable').sortable({
    helper: fixWidthHelper,
    handle: '.handle',
    update: function(e, ui) {
      $.ajax({
        url: $(this).data("url"),
        type: "PATCH",
        data: $(this).sortable('serialize'),
      })
    }
  })

  $('input.custom_date').daterangepicker(dateOptions)
  $('input.custom_date').each(function() {
    if ($(this).val().length > 0) {
      var moment = require('moment-timezone');
      var newValue = moment($(this).val()).format('LL');

      if (newValue !== "Invalid date") {
        $(this).val()
      }
    }
  })
  $('input.custom_date').on('showCalendar.daterangepicker', function(event, picker) {
    $('.monthselect, .yearselect').select2({ width: '50%', theme: "bootstrap4", minimumResultsForSearch: Infinity })
  })
  $('input.custom_date').on('apply.daterangepicker', function(event, picker) {
    $(this).val(picker.startDate.format('LL'))

    var hiddenDateInput = $(this).siblings('input')[0]
    $(hiddenDateInput).val(picker.startDate.format('YYYY-MM-DD'))

    if ($(this).is(".webinar_appointment_voting_start_date") && $('.webinar_appointment_voting_end_date').val().length === 0) {
      $('.webinar_appointment_voting_end_date').val(picker.startDate.format('LL'));
      $('.webinar_appointment_voting_end_date').siblings('input').val(picker.startDate.format('YYYY-MM-DD'))
    }

    if ($(this).hasClass('trigger-filter')) {
      window.submitForm($(this).closest('form'))
    }
  })
  $('input.custom_date').on('cancel.daterangepicker', function(event, picker) {
    $(this).val('')

    var hiddenDateInput = $(this).siblings('input')[0]
    $(hiddenDateInput).val('')
  })

  $('input.custom_datetime').daterangepicker(datetimeOptions)
  $('input.custom_datetime').each(function() {
    if ($(this).val().length > 0) {
      var moment = require('moment-timezone');
      var newValue = moment($(this).val()).format('LLL');

      if (newValue !== "Invalid date") {
        $(this).val()
      }
    }
  })
  $('input.custom_datetime').on('showCalendar.daterangepicker', function(event, picker) {
    $('.monthselect, .yearselect').select2({ width: '50%', theme: "bootstrap4", minimumResultsForSearch: Infinity })
  })
  $('input.custom_datetime').on('show.daterangepicker', function(event, picker) {
    if ($(this).val().length == 0 && $(this).data('default-hour')) {
      $('.daterangepicker .hourselect').val($(this).data('default-hour'))
      $('.daterangepicker .hourselect').trigger("change")
    }

    if ($(this).val().length == 0 && $(this).data('default-minute')) {
      $('.daterangepicker .minuteselect').val($(this).data('default-minute'))
      $('.daterangepicker .minuteselect').trigger("change")
    }
  })
  $('input.custom_datetime').on('apply.daterangepicker', function(event, picker) {
    $(this).val(picker.startDate.format('LLL'))

    var hiddenDateInput = $(this).siblings('input')[0]
    $(hiddenDateInput).val(picker.startDate.format('YYYY-MM-DD HH:mm'))

    if ($(this).is(".webinar_appointment_start_time")) {
      var end_time_input = $(this).parents(".row").find("input.webinar_appointment_end_time")
      if (end_time_input.val().length == 0) {
        var date = picker.startDate
        var hours = end_time_input.data('default-hour') - $(this).data('default-hour')
        date.add(hours, 'hours')

        if (end_time_input.data('default-minute')) {
          date.add(end_time_input.data('default-minute'), 'minutes')
        }

        end_time_input.val(date.format('LLL'));
        end_time_input.siblings('input').val(date.format('YYYY-MM-DD HH:mm'))

        var registrationEndDate = picker.startDate
        registrationEndDate.subtract(3, "days")

        $('input.webinar_appointment_registration_end_date').val(registrationEndDate.format('LL'));
        $('input.webinar_appointment_registration_end_date').siblings('input').val(registrationEndDate.format('YYYY-MM-DD'))

        var discountedTill = picker.startDate
        discountedTill.subtract(8, "weeks")
        $('input.webinar_appointment_discounted_till').val(discountedTill.format('LL'));
        $('input.webinar_appointment_discounted_till').siblings('input').val(discountedTill.format('YYYY-MM-DD'))

        initialize()
      }
    } else if ($(this).hasClass("appointment_proposal_start_time")) {
      var endTimeInput = $(this).parents(".card-body").find(".appointment_proposal_end_time")

      if (endTimeInput.val().length === 0) {
        var date = picker.startDate
        var hours = endTimeInput.data('default-hour') - $(this).data('default-hour')
        date.add(hours, 'hours')

        if (end_time_input.data('default-minute')) {
          date.add(end_time_input.data('default-minute'), 'minutes')
        }

        endTimeInput.val(date.format('LLL'));
        endTimeInput.siblings('input').val(date.format('YYYY-MM-DD HH:mm'))
        initialize()
      }
    }
  })
  $('input.custom_datetime').on('cancel.daterangepicker', function(event, picker) {
    $(this).val('')

    var hiddenDateInput = $(this).siblings('input')[0]
    $(hiddenDateInput).val('')
  })

  $('input.daterange').daterangepicker(daterangeOptions)
  $('input.daterange').on('showCalendar.daterangepicker', function(event, picker) {
    $('.monthselect, .yearselect').select2({ width: '50%', theme: "bootstrap4", minimumResultsForSearch: Infinity })
  })
  $('input.daterange').on('apply.daterangepicker', function(event, picker) {
    $(this).val(picker.startDate.format('L') + ' - ' + picker.endDate.format('L'))

    var startDateInput = $(this).data("startDateInput")
    var endDateInput = $(this).data("endDateInput")

    var names = $(this).attr("name").split("[")
    names.pop()

    var startDateName = names.join("[") + "[" + startDateInput + "]"
    var endDateName = names.join("[") + "[" + endDateInput + "]"
    $("[name='" + startDateName + "']").val(picker.startDate.format('L'))
    $("[name='" + endDateName + "']").val(picker.endDate.format('L'))
  })

  $('input.daterange').on('cancel.daterangepicker', function(event, picker) {
    $(this).val('')

    var startDateInput = $(this).data("startDateInput")
    var endDateInput = $(this).data("endDateInput")

    var names = $(this).attr("name").split("[")
    names.pop()

    var startDateName = names.join("[") + "[" + startDateInput + "]"
    var endDateName = names.join("[") + "[" + endDateInput + "]"

    $("[name='" + startDateName + "']").val('')
    $("[name='" + endDateName + "']").val('')
  })

  tinymce.init(tinymceOptions)

  $('.with-tooltip').tooltip(tooltipOptions)
  $('form abbr[title]').tooltip(tooltipOptions)
}

window.initialize = function() {
  initialize()
}

document.addEventListener("turbolinks:before-cache", function() {
  $("select.select2-hidden-accessible").select2('destroy')

  if ($('input.daterange').length > 0) {
    $('input.daterange').data('daterangepicker').remove()
  }

  $.contextMenu('destroy')

  tinymce.remove()

  $('.with-tooltip').tooltip('hide')
  $('form abbr[title]').tooltip('hide')
})

$(document).on('select2:select', function (e) {
  let event = new Event('change', { bubbles: true })
  e.target.dispatchEvent(event)
})

$(document).on('.only-one-per-group select2:selecting', function(event) {
  $(event.target).find("option[value='" + event.params.args.data.id + "']").parent().children().prop('selected', false)
  $(event.target).select2('destroy').select2(selectOptions);
})

$.fn.modal.Constructor.prototype._enforceFocus = function() {};

var selectOptions = {
  width: '100%',
  theme: "bootstrap4"
}

var daterangeOptions = {
  showDropdowns: true,
  linkedCalendars: false,
  showCustomRangeLabel: false,
  opens: "center",
  drops: "auto",
  autoUpdateInput: false,
  cancelButtonClasses: "btn-secondary",
  locale: {
    cancelLabel: 'Abbrechen/Löschen',
    applyLabel: 'Anwenden'
  }
}

var dateOptions = {
  showDropdowns: true,
  linkedCalendars: false,
  showCustomRangeLabel: false,
  singleDatePicker: true,
  opens: "center",
  drops: "auto",
  autoUpdateInput: false,
  cancelButtonClasses: "btn-secondary",
  locale: {
    format: 'LL',
    cancelLabel: 'Abbrechen/Löschen',
    applyLabel: 'Anwenden'
  }
}

var datetimeOptions = {
  showDropdowns: true,
  linkedCalendars: false,
  showCustomRangeLabel: false,
  singleDatePicker: true,
  timePicker: true,
  timePicker24Hour: true,
  opens: "center",
  drops: "auto",
  autoUpdateInput: false,
  cancelButtonClasses: "btn-secondary",
  locale: {
    format: 'LLL',
    cancelLabel: 'Abbrechen/Löschen',
    applyLabel: 'Anwenden'
  }
}

var tooltipOptions = {
  container : 'body',
  html : true
}

var tinymceOptions = {
  selector: '.tinymce',
  skin: false,
  content_css: false,
  branding: false,
  language: 'de',
  height: 500,
  plugins: 'link, autoresize, pagebreak, image',
  pagebreak_separator: '<div class="alwaysbreak"></div>',
  a11y_advanced_options: true,
  relative_urls : false,
  remove_script_host: false,
  convert_urls: false,
}
