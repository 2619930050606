$(document).on("change", ".voting", function() {
  if ($(this)[0].checked) {
    $(".with_voting").addClass("d-block").removeClass("d-none")
    $(".without_voting").addClass("d-none").removeClass("d-block")
  } else {
    $(".with_voting").addClass("d-none").removeClass("d-block")
    $(".without_voting").addClass("d-block").removeClass("d-none")
  }
})

toggle_webinar_category = function() {
  let category = $("#webinar_category").val()

  $(".topic-container").addClass("d-none").removeClass("d-block")
  $(".available-places-content").removeClass("d-none").addClass("d-block")

  $(".update-webinar-content").addClass("d-none")
  $(".update-webinar-content input").prop("disabled", true)

  $(".additional-webinar-content").addClass("d-none")
  $(".additional-webinar-content input").prop("disabled", true)

  $(".exchange-webinar-content").addClass("d-none")
  $(".exchange-webinar-content input").prop("disabled", true)

  $(".consultation-webinar-content").addClass("d-none")
  $(".consultation-webinar-content input").prop("disabled", true)

  $(".added_value-webinar-content").addClass("d-none")
  $(".added_value-webinar-content input").prop("disabled", true)

  $("." + category + "-webinar-content").removeClass("d-none")
  $("." + category + "-webinar-content input").prop("disabled", false)

  if (category == "update" || category == "additional") {
    $(".topic-container").removeClass("d-none").addClass("d-block")
  }

  if (category == "added_value") {
    $(".available-places-content").addClass("d-none").removeClass("d-block")
    $(".available-places-content input").val(500)
  }
}

$(document).on("change", "#webinar_category", toggle_webinar_category)

document.addEventListener("turbolinks:load", toggle_webinar_category)

$(document).on('change', '.update-cost-data', function() {
  let data = $(this).data('cost-data')
  let cost_unit = data[$(this).val()]["cost_unit"]
  let cost_center = data[$(this).val()]["cost_center"]

  $('.cost_unit').val(cost_unit)
  $('.cost_center').val(cost_center)
})