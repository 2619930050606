const Uppy = require('@uppy/core')
const German = require('@uppy/locales/lib/de_DE')
const Dashboard = require('@uppy/dashboard')
const ActiveStorageUpload = require('@excid3/uppy-activestorage-upload')

document.addEventListener('turbolinks:load', () => {
  document.querySelectorAll('[data-uppy]').forEach(element => setupUppy(element))
})

document.addEventListener('turbolinks:load', () => {
  $('#modal').on('shown.bs.modal', function (event) {
    document.querySelectorAll('[data-uppy]').forEach(element => setupUppy(element))
  })
})

function setupUppy(element) {
  let form = element.closest('form')
  let direct_upload_url = document.querySelector("meta[name='direct-upload-url']").getAttribute("content")
  let field_name = element.dataset.uppy

  let uppy = Uppy({
    autoProceed: true,
    allowMultipleUploads: false,
    logger: Uppy.debugLogger,
    locale: German,
    restrictions: {
      maxFileSize: 10485760,
      allowedFileTypes: ['application/pdf']
    },
  })

  uppy.use(ActiveStorageUpload, {
    directUploadUrl: direct_upload_url
  })

  uppy.use(Dashboard, {
    inline: true,
    target: '.uppy-container',
    proudlyDisplayPoweredByUppy: false,
    height: 220,
    note: 'Pdf-Dateien, 10MB oder weniger'
  })

  uppy.on('complete', (result) => {
    element.querySelectorAll('[data-pending-upload]').forEach(element => element.parentNode.removeChild(element))

    result.successful.forEach(file => {
      appendUploadedFile(element, file, field_name)
      setPreview(element, file)
      setPdfMeta(element, file)
    })

    uppy.reset()
  })
}

function setPdfMeta(element, file) {
  let direct_upload_url = document.querySelector("meta[name='direct-upload-url']").getAttribute("content")
  let pdfMeta = file.response.pdf_meta

  let containerElement = element.querySelector('[data-behavior="uppy-preview-container"]')
  let sizeElement = element.querySelector('[data-behavior="uppy-document-size"]')
  let countElement = element.querySelector('[data-behavior="uppy-pages-count"]')
  let costsElement = element.querySelector('[data-behavior="uppy-costs"]')
  let submitButtonElement = document.querySelector('[data-behavior="uppy-submit"]')
  let price = parseFloat(costsElement.dataset.price)

  sizeElement.innerHTML = pdfMeta.size

  fetch(`${direct_upload_url}/${file.response.signed_id}`)
    .then(response => response.json())
    .then(data => {
      let count = `${data.count} Seite(n)`
      countElement.innerHTML = count

      if (price !== 0) {
        let costs = ` ${Math.round((price * data.count) * 100) / 100} €`
        costsElement.innerHTML = costs
      }

      show(containerElement, submitButtonElement)
    });

}

function appendUploadedFile(element, file, field_name) {
  const hiddenField = document.createElement('input')

  hiddenField.setAttribute('type', 'hidden')
  hiddenField.setAttribute('name', field_name)
  hiddenField.setAttribute('data-pending-upload', true)
  hiddenField.setAttribute('value', file.response.signed_id)

  element.appendChild(hiddenField)
}

function setPreview(element, file) {
  let preview = element.querySelector('[data-behavior="uppy-preview"]')
  if (preview) {
    let pdfMeta = file.response.pdf_meta
    let src = (pdfMeta.preview) ? pdfMeta.preview : "https://encrypted-tbn0.gstatic.com/images?q=tbn%3AANd9GcSpj0DBTVsaja01_xWh37bcutvpd7rh7zEd528HD0d_l6A73osY"
    console.log(src)
    preview.src = src
  }
}

function show(...elements) {
  elements.forEach(element => {
    element.classList.remove('d-none')
  })
}

$(document).on("click", ".btn-revise", function (event) {
  event.preventDefault()
  $(".chat__message-body-input").val($(this).data("body"))
  $(".chat__message-body-input").focus()
  $(this).closest(".chat__review-buttons").remove()
})
