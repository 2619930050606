let timeout = null

$(document).on("click", ".clear-search", function() {
  $(this).siblings('.user_search').find('input')[0].value = '';
  let form = $(this).closest('form');

  clearTimeout(timeout);
  window.submitForm(form)
});

$(document).on("keyup", ".trigger-search", function(event) {
  clearTimeout(timeout);

  if (event.keyCode !== 13) {
    let form = $(this).closest('form');
    timeout = setTimeout(function() {
      window.submitForm(form)
    }, 500);
  }
});

$(document).on("change", ".trigger-filter", function() {
  const form = $(this).closest('form');
  window.submitForm(form)
});

window.submitForm = function(form) {
  $.ajax({
    type: form[0].method,
    url: form[0].action,
    dataType: "script",
    data: form.serialize()
  });
}
